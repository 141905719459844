/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useTheme } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
// import Grid from '@mui/material/Grid';
// import TextField from '@mui/material/TextField';
// import Button from '@mui/material/Button';
// // import Divider from '@mui/material/Divider';
// import Avatar from '@mui/material/Avatar';
// import IconButton from '@mui/material/IconButton';
// import FacebookIcon from '@mui/icons-material/Facebook';
// import TwitterIcon from '@mui/icons-material/Twitter';
// import InstagramIcon from '@mui/icons-material/Instagram';

const DesignThinking = () => {
  const theme = useTheme();
 
  // const photos = [
  //   {
  //     src: 'https://assets.maccarianagency.com/backgrounds/img25.jpg',
  //     rows: 1,
  //     cols: 2,
  //   },
  //   {
  //     src: 'https://assets.maccarianagency.com/backgrounds/img22.jpg',
  //     rows: 1,
  //     cols: 1,
  //   },
  //   {
  //     src: 'https://assets.maccarianagency.com/backgrounds/img24.jpg',
  //     rows: 1,
  //     cols: 1,
  //   },
  //   {
  //     src: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
  //     rows: 1,
  //     cols: 2,
  //   },
  // ];

  return (
    <Box>
      <Box paddingX={{ xs: 0, sm: 4, md: 6 }}>
        <Typography variant='h5' align='center'>
        Design Thinking
        </Typography>
        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        <br/>Senior Science Association organised an activity on “Design Thinking” for classes 9 to 12 on 26 August 2022. It was a team event and in the allotted time of 20 minutes, using the objects the team was asked to demonstrate a concept/ an act based on the working principle or the intrinsic property of the object and also web a story that had to be enacted in 3 minutes. The students participated enthusiastically. They beautifully brought about the relevance of Science and the transition of concept in the story. The knowledge of Science amalgamated with creativity captured the attention of the audience.
        </Typography>
        <Typography align='center' fontWeight={500}>
        <br/>LOOK AT USUAL THINGS WITH UNUSUAL EYES
        </Typography>

      </Box>
    </Box>
  );
};

export default DesignThinking;
